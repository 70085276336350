import {initializeApp} from 'firebase/app'
import {getDatabase} from 'firebase/database'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FBDB_APIKEY,
  authDomain: process.env.VUE_APP_FBDB_authDomain,
  projectId: process.env.VUE_APP_FBDB_projectId,
  storageBucket: process.env.VUE_APP_FBDB_storageBucket,
  messagingSenderId: process.env.VUE_APP_FBDB_messagingSenderId,
  appId: process.env.VUE_APP_FBDB_appId,
  measurementId: process.env.VUE_APP_FBDB_measurementId,
  databaseURL: process.env.VUE_APP_FBDB_databaseURL
}

initializeApp(firebaseConfig)

export const firestoreDb = getFirestore()
export const realtimeDb = getDatabase()
export const storage = getStorage()
