<template>
  <div
    class="app-container"
    :class="{ 'app-mobile': isDevice, 'app-mobile-dark': theme === 'dark' }"
  >
    <chat-container
      v-if="showChat"
      :userId="userid"
      :usernameId="username"
      :theme="theme"
      :is-device="isDevice"
      @show-options="showOptions = $event"
    />
  </div>
</template>

<script>

import ChatContainer from './ChatContainer'

export default {
  components: {
    ChatContainer
  },
  props: {
    theme: {type: String, required: true},
    userid: {type: String, required: false, default: ''},
    username: {type: String, required: false, default: ''},
    site: {type: String, required: true},
    enableoptions: {type: Boolean, required: false, default: true},
  },
  data() {
    return {
      showChat: true,
      isDevice: false,
      users: [
        {
          _id: '6R0MijpK6M4AIrwaaCY2',
          username: 'Luke',
          avatar: 'https://66.media.tumblr.com/avatar_c6a8eae4303e_512.pnj'
        },
        {
          _id: 'SGmFnBZB4xxMv9V4CVlW',
          username: 'Leia',
          avatar: 'https://avatarfiles.alphacoders.com/184/thumb-184913.jpg'
        },
        {
          _id: '6jMsIXUrBHBj7o2cRlau',
          username: 'Jackson',
        },
        {
          _id: '6jMsIXUrBHBj7o2cRlauasd',
          username: 'MMAKing'
        }
      ],
    }
  },

  computed: {
    showToolOptions() {
      return this.enableoptions
    }
  },

  watch: {
    userid() {
      console.log('watch user')
      this.showChat = false
      setTimeout(() => (this.showChat = true), 150)
      return this.userid
    },
    username() {
      console.log('watch username')
      console.log(this.username)
      return this.username
    },
  },

  mounted() {
    this.isDevice = window.innerWidth < 500
    window.addEventListener('resize', ev => {
      if (ev.isTrusted) this.isDevice = window.innerWidth < 500
    })
  },
  methods: {
    getIP() {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(response => {
          this.clientIp = response.ip
        })
    },
    getUser() {

    }
  }
}
</script>

<style lang="scss">

body {
  margin: 0;
}

input {
  -webkit-appearance: none;
}

.app-container {
  font-family: 'Lato', sans-serif;
}

.app-mobile {
  padding: 0;

  &.app-mobile-dark {
    background: #131415;
  }

  .user-logged {
    margin: 10px 5px 0 10px;
  }

  select {
    margin: 10px 0;
  }

  .button-theme {
    margin: 10px 10px 0 0;

    .button-github {
      height: 23px;

      img {
        height: 23px;
      }
    }
  }
}

.user-logged {
  font-size: 12px;
  margin-right: 5px;
  margin-top: 10px;

  &.user-logged-dark {
    color: #fff;
  }
}

select {
  height: 20px;
  outline: none;
  border: 1px solid #e0e2e4;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 20px;
}

.button-theme {
  float: right;
  display: flex;
  align-items: center;

  .button-light {
    background: #fff;
    border: 1px solid #46484e;
    color: #46484e;
  }

  .button-dark {
    background: #1c1d21;
    border: 1px solid #1c1d21;
  }

  button {
    color: #fff;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 6px 12px;
    margin-left: 10px;
    border: none;
    font-size: 14px;
    transition: 0.3s;
    vertical-align: middle;

    &.button-github {
      height: 30px;
      background: none;
      padding: 0;
      margin-left: 20px;

      img {
        height: 30px;
      }
    }

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }

    @media only screen and (max-width: 768px) {
      padding: 3px 6px;
      font-size: 13px;
    }
  }
}

.version-container {
  padding-top: 20px;
  text-align: right;
  font-size: 14px;
  color: grey;
}

.v-btn.success {
  background-color: #56ca00;
  min-width: 80px;
}
</style>
