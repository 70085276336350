import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App'
import vueCustomElement from 'vue-custom-element'
import vuetify from './plugins/vuetify'
import 'document-register-element/build/document-register-element'

Vue.config.productionTip = false
Vue.use(vueCustomElement)
Vue.use(vuetify)

App.vuetify = vuetify

Vue.customElement('kombat-chat', App, {
  shadow: true,
  // shadowCss: 'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css',
  beforeCreateVueInstance(root) {
    const rootNode = root.el.getRootNode();

    if (rootNode instanceof ShadowRoot) {
      // eslint-disable-next-line no-param-reassign
      root.shadowRoot = rootNode;
    } else {
      // eslint-disable-next-line no-param-reassign
      root.shadowRoot = document.head;
    }
    return root;
  },
});
