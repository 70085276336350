<template>
  <div class="text-center">
    <v-dialog
      light
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ title }}
        </v-card-title>
        <v-card-text>

          <div id="buttons" class="text-center" v-if="!showSignup && !showSignin">
            <v-btn @click="showSignupForm" color="default">Create account</v-btn>
            <br><br>
            <v-btn @click="showSigninForm" color="default">Sign in</v-btn>

            <v-img
              v-ripple
              alt="TwitterLogin"
              class="mx-auto text-center hidden"
              style="max-width: 250px"
              src="https://kombatchat.pages.dev/twitter_button.png"
              @click="createTwitterUser"
            >
            </v-img>
          </div>
          <div id="signup" v-if="showSignup">
            <v-form>
              <v-avatar
                v-if="showSignup"
                class="usernamea"
                size="40px"
              >
                <img
                  v-if="avatar"
                  alt="Avatar"
                  :src="avatar"
                >
              </v-avatar>
              <v-text-field
                v-model="username"
                label="Username"
                placeholder="JohnDoe"
                :rules="userrules"
              ></v-text-field>

              <v-text-field
                v-model="email"
                label="Email"
                placeholder="john@example.com"
                :rules="rules"
              ></v-text-field>

              <v-text-field
                v-model="password"
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Password"
                placeholder="············"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
                :rules="rules"
              ></v-text-field>
              <p class="text-xs mt-1  "> By creating an account, you're agreeing to FantasyKombat's
                <a
                  target="_blank" href="http://fantasykombat.com/terms-of-service">Terms of
                  Service</a> and <a target="_blank"
                                     href="http://fantasykombat.com/privacy-policy">Privacy
                  Policy.</a></p>
              <br>
              <v-btn
                small
                text
                @click="showSigninForm">
                Already have an account?
              </v-btn>
              <v-btn
                @click="createUser"
                color="success"
                class="mt-1 float-right"
                :loading="loading"
              >
                Sign Up
              </v-btn>
              <br>
              <br>
            </v-form>
          </div>
          <div id="signin" v-if="showSignin">
            <v-form>
              <v-text-field
                v-model="email"
                label="Email"
                placeholder="john@example.com"
              ></v-text-field>

              <v-text-field
                v-model="password"
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Password"
                placeholder="············"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
              <br>
              <v-btn
                small
                @click="showSignupForm">
                Create Account
              </v-btn>
              <v-btn
                @click="signinUser"
                color="success"
                :loading="loading"
                class="mt-1 float-right"
              >
                Sign in
              </v-btn>
              <br>
              <br>
            </v-form>
          </div>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  getAuth,
  signInWithPopup,
  TwitterAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile
} from 'firebase/auth'
import {addIdentifiedUser} from '@/database/firestore'
import * as firestoreService from "@/database/firestore"
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline
} from '@mdi/js'

export default {
  props: {
    signinModal: {type: Boolean, default: false},
    roomId: {type: String, default: ''}
  },
  data() {
    return {
      title: 'Sign in',
      showSignup: false,
      showSignin: false,
      userrules: [v => v.length >= 3 || 'Min 3 characters'],
      rules: [v => v.length >= 6 || 'Min 6 characters'],
      dialog: false,
      isPasswordVisible: false,
      id: '',
      email: '',
      username: '',
      password: '',
      avatar: '',
      ip: '',
      loading: false,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  watch: {
    username() {
      this.avatar = `https://avatars.dicebear.com/api/initials/:${this.username}.svg`
    },
    signinModal() {
      this.dialog = this.signinModal
      console.log(this.dialog)
      if (!this.dialog) {
        this.$parent.openSignin = false
      }
    }
  },
  mounted() {
    fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ip}) => {
        this.ip = ip
      })
  },
  methods: {
    showSigninForm() {
      this.title = 'Sign in'
      this.showSignin = true
      this.showSignup = false
    },
    showSignupForm() {
      this.title = 'Join'
      this.showSignup = true
      this.showSignin = false
    },
    signOut() {
      const auth = getAuth()

      auth.signOut().then(function () {
        console.log('Signed Out');
      }, function (error) {
        console.error('Sign Out Error', error);
      });

    },
    createTwitterUser() {
      const provider = new TwitterAuthProvider()
      const auth = getAuth()
      signInWithPopup(auth, provider)
        .then((result) => {

          // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
          // You can use these server side with your app's credentials to access the Twitter API.
          const credential = TwitterAuthProvider.credentialFromResult(result)
          const token = credential.accessToken
          const secret = credential.secret

          // The signed-in user info.
          const user = result.user
          console.log(user)
          this.logChatuser(user)
          this.dialog = false

        }).catch((error) => {
        console.log(error)
      })
    },
    logChatuser(user) {
      const userData = {
        _id: user.uid,
        username: this.username,
        avatar: this.avatar,
        ip: this.ip
      }
      addIdentifiedUser(user.uid, userData)
    },
    createUser() {
      this.loading = true
      const auth = getAuth()
      createUserWithEmailAndPassword(auth, this.email, this.password)
        .then((data) => {
          const user = data.user
          console.log(user)
          document.cookie = `fkId=${user.uid}; SameSite=None; Secure`
          this.avatar = user.photoURL
          this.currentUserId = user.uid
          if (!this.avatar) {
            this.avatar = `https://avatars.dicebear.com/api/initials/:${this.username}.svg`
          }
          this.$parent.currentUserId = this.currentUserId
          this.$parent.username = this.username
          this.$parent.avatar = this.avatar
          updateProfile(auth.currentUser, {
            displayName: this.username, photoURL: this.avatar
          }).then(() => {
            // Profile updated!
            // ...
          }).catch((error) => {
            // An error occurred
            // ...
          });
          this.logChatuser(user)
          firestoreService.addRoomUser(this.roomId, user.uid)
          this.dialog = false
          this.loading = false
          this.dialog = false

        })
        .catch((error) => {
          this.loading = false
          const errorCode = error.code
          const errorMessage = error.message
          // ...
        })
    },
    signinUser() {
      this.loading = true
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log(user)
          document.cookie = `fkId=${user.uid}; SameSite=None; Secure`
          this.avatar = user.photoURL
          this.currentUserId = user.uid
          this.$parent.currentUserId = this.currentUserId
          this.$parent.username = this.username
          this.$parent.avatar = this.avatar
          firestoreService.addRoomUser(this.roomId, user.uid)
          this.loading = false
          this.dialog = false
          console.log(this.currentUserId)
          this.logChatuser(user)
        })
        .catch((error) => {
          this.loading = false
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    }
  },
}
</script>
