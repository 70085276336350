var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vac-format-message-wrapper",class:{ 'vac-text-ellipsis': _vm.singleLine }},[(!_vm.textFormatting.disabled)?_c('div',{class:{ 'vac-text-ellipsis': _vm.singleLine }},_vm._l((_vm.linkifiedMessage),function(message,i){return _c('div',{key:i,staticClass:"vac-format-container"},[_c(message.url ? 'a' : 'span',{tag:"component",class:{
						'vac-text-ellipsis': _vm.singleLine,
						'vac-text-bold': message.bold,
						'vac-text-italic': _vm.deleted || message.italic,
						'vac-text-strike': message.strike,
						'vac-text-underline': message.underline,
						'vac-text-inline-code': !_vm.singleLine && message.inline,
						'vac-text-multiline-code': !_vm.singleLine && message.multiline,
						'vac-text-tag': !_vm.singleLine && !_vm.reply && message.tag
					},attrs:{"href":message.href,"target":message.href ? _vm.linkOptions.target : null,"rel":message.href ? _vm.linkOptions.rel : null},on:{"click":function($event){return _vm.openTag(message)}}},[_vm._t("deleted-icon",function(){return [(_vm.deleted)?_c('svg-icon',{staticClass:"vac-icon-deleted",attrs:{"name":"deleted"}}):_vm._e()]},null,{ deleted: _vm.deleted }),(message.url && message.image)?[_c('div',{staticClass:"vac-image-link-container"},[_c('div',{staticClass:"vac-image-link",style:({
									'background-image': ("url('" + (message.value) + "')"),
									height: message.height
								})})]),_c('div',{staticClass:"vac-image-link-message"},[_c('span',[_vm._v(_vm._s(message.value))])])]:(message.value=='#make bet#')?[_c('div',{staticClass:"vac-image-link-container"},[_vm._v(" Bet ")])]:[(_vm.isEmoji(message.value) && message.value.length == 2)?_c('span',{staticStyle:{"font-size":"35px"}},[_vm._v(_vm._s(message.value))]):_c('span',[_vm._v(_vm._s(message.value))])]],2)],1)}),0):_c('div',[_vm._v(" "+_vm._s(_vm.formattedContent)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }