import { render, staticRenderFns } from "./RoomHeader.vue?vue&type=template&id=012a8b18&"
import script from "./RoomHeader.vue?vue&type=script&lang=js&"
export * from "./RoomHeader.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./RoomHeader.vue?vue&type=style&index=0&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
